<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Aplicaciones BpB Admin</h1>
		</div>
		<br />

		<div class="d-flex flex-wrap">
      <div v-for="item in userMenu" :key="item.route_name" class="col-md-4">
        <div class="bpb-card">
          <div class="card-body">
            <div class="text-center">
              <img :src="item.img.src" :alt="item.img.alt" width="80px" />
            </div>
            <h4 class="card-title">{{ item.title }}</h4>
            <p class="card-subtitle text-muted" v-if="item.subtitle">{{ item.subtitle }}</p>
            <router-link :class="item.disabled && 'disabled'" :to="{name: item.route_name}" class="btn complementary-btn">Acceder</router-link>
          </div>
        </div>
      </div>
		</div>


	</div>
</template>

<script type="text/javascript">
	export default {
    data(){
      return {
        itemsMenu: [
          {
            disabled: false,
            app: 'bpb_apps_admin',
            route_name: 'bpb_apps_aplicaciones_index',
            title: 'Aplicaciones',
            subtitle: 'Administrar aplicaciones',
            permisos_validacion: 'aplicaciones_listar',
            img: { src: 'https://static.bienparabien.com/constelacion/img/aplicaciones.png', alt: 'Aplicaciones' }
          }

        ],
        userMenu: [],
      }
    },
		mounted: function() {
			let menu = {title: 'Aplicaciones BpB Admin'};
			this.$store.commit('setMenu',[menu]);
      this.loadMenu(menu);
		},
    methods: {
      loadMenu(menu){
        for (const item of this.itemsMenu) {
         if (this.$auth.can(item.app, item.permisos_validacion) && !item.disabled) {
           this.userMenu.push(item);
           menu[item.route_name] = item.title;
         }
        }
      }
    }
	}
</script>
